import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import Facebook from "./IMG/Social-Fix/facebook.png";
import instagram from "./IMG/Social-Fix/instagram.png";
import twitter from "./IMG/Social-Fix/twitter.png";
import youtube from "./IMG/Social-Fix/youtube.png";
import Logo from "./IMG/Social-Fix/New Logo.svg";
import './Style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const PreviewForm = () => {
    const [imageSrc, setImageSrc] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const imageRef = useRef(null);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const file = formData.get('imageUpload');

        if (!file) {
            alert("Please upload an image.");
            return;
        }

        const reader = new FileReader();
        reader.onload = function (event) {
            setImageSrc(event.target.result);
            setTitle(formData.get('title'));
            setDescription(formData.get('description'));
        };
        reader.readAsDataURL(file);
    };

    const generatePoster = () => {
        const posterElement = document.querySelector('.box-poster');
        html2canvas(posterElement, {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            logging: true, // Enable logging to console for debugging
            onclone: function (documentClone) {
                documentClone.querySelector('.Main-Title').style.fontFamily = 'Noto Sans Gujarati, sans-serif';
            }
        }).then(function (canvas) {
            const imageData = canvas.toDataURL("image/png");
            const downloadLink = document.createElement('a');
            downloadLink.href = imageData;
            downloadLink.download = 'poster.png';
            downloadLink.click();
        }).catch(function (error) {
            console.error('Error generating poster:', error);
        });
    };

    return (
        <div className="container">
            <h1 className="preview-title">Instagram Post Generator</h1>
            <form className='Mail-form' onSubmit={handleFormSubmit}>
                <FormGroup>
                    <Label for="imageUpload">Upload Image</Label>
                    <Input type="file" id="imageUpload" name="imageUpload" accept="image/*" required />
                </FormGroup>
                <FormGroup>
                    <Label for="title">Title</Label>
                    <Input type="text" id="title" name="title" required onChange={(e) => setTitle(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="description">Description</Label>
                    <Input type="textarea" id="description" name="description" rows="3" minLength="85" required onChange={(e) => setDescription(e.target.value)} />
                </FormGroup>
                <Button type="submit" color="primary">Generate Preview</Button>
            </form>

            <div className="box-poster mt-5">
                <div className="Img-position">
                    <img className="Bnner-img" src={imageSrc} alt="" ref={imageRef} onLoad={generatePoster} />
                    <div className="Logo">
                        <img className="log" src={Logo} alt="" />
                    </div>
                </div>
                <div className="Date">
                    <p className="style-date">{new Date().toLocaleDateString()}</p>
                </div>
                <p className="Main-Title">{title}</p>
                <p className="Subtitle">{description}</p>
                <div className="onlineplatform">
                    <p className="web-link">www.gujaratvandan.com</p>
                    <p className="web-link-2">
                        <img className="social-img" src={Facebook} alt="" />
                        <img className="social-img" src={instagram} alt="" />
                        <img className="social-img" src={twitter} alt="" />
                        <img className="social-img" src={youtube} alt="" />
                        /gujaratvandan
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PreviewForm;
